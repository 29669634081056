
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    // 404.js
import Layout from '@layout';
import MainLayout from '@layout/Main';
import ErrorModule from '@modules/Error';
import { ReactElement } from 'react';
FourOhFour.getLayout = function getLayout(page: ReactElement) {
    return (<Layout>
      <MainLayout hasHeader={false}>
        {page}
      </MainLayout>
    </Layout>);
};
export default function FourOhFour() {
    return <ErrorModule status={404}/>;
}

    async function __Next_Translate__getStaticProps__18c5fb7195c__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18c5fb7195c__ as getStaticProps }
  